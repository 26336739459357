import { useEffect, useMemo, useRef, useState } from "react";
import { useMutableData } from "API/useData";
import qs from "query-string";
import { useFormContext } from "react-hook-form";

import { checkVendorNameAvailability } from "Redux/DataCalls/Disbursement.api";

import { CustomFieldType, FieldType } from "Views/Bills/V2/components/FormGenerator/type";

import { useBill } from "Views/Bills/V2/context/BillContext";

import {
  API_URL,
  RECIPIENT_CHECKBOX_FIELDS,
  RECIPIENT_STATIC_FIELDS,
  RECIPIENT_TAGINPUT_FIELDS,
  VALIDATION_MSG,
} from "Views/Bills/V2/constants";
import {
  formFieldsMapper,
  getParents,
  getDynamicFieldsIdentifier,
  orderFields,
  removeDuplicateFields,
  getRefetchFieldsIdentifier,
  defineDefaultValue,
  transformOCRValue,
} from "./helper";
import { FieldGenerator, OCRData } from "Views/Bills/V2/BillForm/type";
import { MappedDynamicFormFields } from "./types";

/**
 * This hooks is a dynamic fields generator that created
 * to prepare the possibilities of moving this to the
 * BE when it becoming too complex.
 * PRETEND THAT THIS IS AN API RESPONSE
 * @param body an object filled with conditions for adding/removing fields
 * @returns formFields used for BillDetail form
 */
const getRecipientDetailFields = (isEdit?: boolean) => {
  const checkVendorNameValidator = async (legalName: string) => {
    if (isEdit) {
      return true;
    }
    try {
      await checkVendorNameAvailability(legalName);
      return true;
    } catch (error) {
      return VALIDATION_MSG.duplicateRecipientName;
    }
  };

  const fields: FieldGenerator[] = [
    {
      label: "Recipient",
      name: "legalName",
      rules: {
        required: VALIDATION_MSG.required("Recipient"),
        validate: checkVendorNameValidator,
      },
      fieldProps: {
        fieldType: FieldType.INPUT,
        placeholder: "Enter Recipient Nickname",
        tooltip: "Recipient will be saved under this nickname that is only visible to your organization.",
      },
    },
    {
      label: "Recipient Country and Currency",
      name: "countryAndCurrency",
      tooltip: "The currency defined here will be the\ncurrency received by recipient",
      fieldGroup: [
        {
          name: "countryCode",
          rules: {
            required: VALIDATION_MSG.required("Recipient Country"),
          },
          fieldProps: {
            isOnChangeRefetch: true,
            placeholder: "Enter Recipient Country",
            fieldType: CustomFieldType.RECIPIENT_COUNTRY_SELECT,
          },
        },
        {
          name: "currencyCode",
          rules: {
            required: VALIDATION_MSG.required("Recipient Currency"),
          },
          fieldProps: {
            isOnChangeRefetch: true,
            placeholder: "Enter Recipient Currency",
            fieldType: CustomFieldType.RECIPIENT_CURRENCY_SELECT,
          },
        },
      ],
    },
  ];

  return {
    data: {
      payload: {
        fields,
      },
    },
  };
};

// Only for Country Code
export const EXCLUDE_ON_CHANGE_COUNTRY_CODE = ["beneficiaryBankName", "swiftCode"];

/**
 * Will replace it with the real API
 * @returns swr object like
 */
export const useRecipientDetailFields = (stringifyUrl: string, ocrData: OCRData) => {
  const { url, query } = qs.parseUrl(stringifyUrl, { parseBooleans: true });
  const {
    vendorID,
    currencyCode: currency,
    countryCode: destinationCountry,
    isNewData,
    ...dynamicFieldQueries
  } = query || {};

  // IMPROVEMENT: if the BE changed the API by not giving the field
  // partially, we could remove the this hooks
  const { getValues, unregister, resetField, setValue, formState, clearErrors } = useFormContext();
  const { setDynamicFieldsIdentifier } = useBill();

  const requestKeyRef = useRef<string>(undefined);
  // to not take cache data for apiURL
  const randomNumber = useRef(Date.now());
  const [apiURL, setAPIURL] = useState<string>();
  const [dynamicFields, setDynamicFields] = useState([]);

  // states for conditional validations
  const [isFirstfetch, setIsFirstFetch] = useState(true);
  const [isSkipChain, setIsSkipChain] = useState(true);
  const [isCountryCodeChanged, setIsCountryCodeChanged] = useState(false);

  const isFetch = currency && destinationCountry;
  const isEdit = Boolean(vendorID);
  const [dynamicFieldsKey] = Object.keys(dynamicFieldQueries);
  const fieldValue = dynamicFieldQueries?.[dynamicFieldsKey];

  // manually reset on destinationCountry is changed
  useEffect(() => {
    setIsCountryCodeChanged(formState.dirtyFields.countryCode);
    EXCLUDE_ON_CHANGE_COUNTRY_CODE.forEach((alias) => {
      unregister(`dynamicFields.${alias}`);
    });
  }, [destinationCountry]);

  /** set API URL to fetch form fields - START - */
  // reset dynamic fields everytime the
  // currency, country, or vendorID is changed
  useEffect(() => {
    if (isFetch) {
      // manual reset field
      unregister("dynamicFields.paymentMethod");

      setDynamicFields([]);
      setDynamicFieldsIdentifier(undefined);
      setAPIURL(
        qs.stringifyUrl({
          url,
          query: {
            currency,
            destinationCountry,
            vendorID: isFirstfetch ? vendorID : undefined,
            requestKey: requestKeyRef.current,
          },
        })
      );
    }
  }, [currency, destinationCountry, vendorID]);

  // set API url everytime the dynamic fields key (fieldID)
  // or fieldValue or isNewData is changed
  useEffect(() => {
    if (isFetch) {
      const refetchFieldsIdentifier = getRefetchFieldsIdentifier(dynamicFields);
      const fieldValue =
        typeof dynamicFieldQueries[dynamicFieldsKey] === "boolean"
          ? dynamicFieldQueries[dynamicFieldsKey]
            ? "1"
            : "0"
          : dynamicFieldQueries[dynamicFieldsKey];
      const { fieldID } = refetchFieldsIdentifier[dynamicFieldsKey] || {};

      if (fieldID && (isNewData || fieldValue)) {
        setAPIURL(
          qs.stringifyUrl({
            url,
            query: {
              currency,
              destinationCountry,
              requestKey: requestKeyRef.current,
              fieldID,
              fieldValue: isNewData ? undefined : fieldValue,
              isNewData,
            },
          })
        );
      }
    }
  }, [isNewData, dynamicFieldsKey, fieldValue]);
  /** set API URL to fetch form fields - END - **/

  const { fields: staticFields } = useMemo(() => getRecipientDetailFields(isEdit).data.payload, [isEdit]);

  const { data: dynamicFieldsData, isValidating } = useMutableData(isFetch ? [apiURL, randomNumber] : null, {
    onSuccess: (res) => {
      requestKeyRef.current = res?.data?.payload?.key;
      if (isFirstfetch) setIsFirstFetch(false);
    },
  });

  const { data: recipientDetail } = useMutableData(vendorID ? `${API_URL.recipientDetail}/${vendorID}` : null);

  const setDynamicFieldsState = (dynamicFields: MappedDynamicFormFields[]) => {
    const dynamicFieldsIdentifier = getDynamicFieldsIdentifier(dynamicFields);
    setDynamicFieldsIdentifier(dynamicFieldsIdentifier);
    setDynamicFields(dynamicFields);
  };

  // handle chaining fetch on new fields (dynamicFieldsData)
  const doFetchOnFilled = (fields) => {
    if (isFetch) {
      const { fieldID, alias, defaultValue } = fields.find((item) => item.fieldProps.isOnChangeRefetch) || {};
      const fieldValue = getValues(`dynamicFields.${alias}`) || defaultValue;
      const isEnableNewData = isNewData && alias === "beneficiaryBankName"; // specific for recipient bank;

      if (fieldID && (isEnableNewData || fieldValue)) {
        setAPIURL(
          qs.stringifyUrl({
            url,
            query: {
              currency,
              destinationCountry,
              requestKey: requestKeyRef.current,
              fieldID,
              fieldValue: isNewData ? undefined : fieldValue,
              isNewData: isEnableNewData,
            },
          })
        );

        return true;
      }
    }

    return false;
  };

  const retainDynamicData = (field) => {
    clearErrors();
    const { name, alias, defaultValue } = field;
    const { recipientData } = ocrData || {};

    let value = getValues(name);

    // manually set value to empty on change country
    if (EXCLUDE_ON_CHANGE_COUNTRY_CODE.includes(alias) && isCountryCodeChanged) {
      setValue(name, null);

      return;
    }

    if (recipientData && (value === undefined || value === null)) {
      value = transformOCRValue(name, recipientData[name]);
    }

    switch (alias) {
      case "beneficiaryBankName": {
        const recipientBankValue = value || defaultValue;
        const options = field?.fieldProps?.options || [];

        if (recipientBankValue && !isEdit) {
          const selectedOptions = options.find(
            (item) =>
              item.label.toLowerCase().includes(recipientBankValue.toLowerCase()) ||
              item.value.toLowerCase() === recipientBankValue.toLowerCase()
          );

          setValue(name, selectedOptions?.value);
        }

        break;
      }
      case "swiftCode": {
        setValue(name, defaultValue || "", {
          shouldValidate: true,
        });
        break;
      }
      case "beneficiaryName":
      case "beneficiaryBankAccountNumber":
      case "paymentPurpose":
        setValue(name, value || defaultValue);
        break;
      default: {
        setValue(name, value || defaultValue, {
          shouldValidate: true,
        });
      }
    }
  };

  // transform the dynamic fields into the FormGenerator data type
  // only triggers when the dynamicFieldsData changed
  useEffect(() => {
    const fields = dynamicFieldsData?.data?.payload?.fields;

    if (fields) {
      if (isFirstfetch) setIsFirstFetch(false);

      const mappedFields = formFieldsMapper(fields);

      // return it back to false after used
      if (isCountryCodeChanged) {
        setIsCountryCodeChanged(false);
      }

      if (!dynamicFieldsKey) {
        const newDynamicFields = orderFields(removeDuplicateFields(mappedFields));

        newDynamicFields.forEach(retainDynamicData);

        setDynamicFieldsState(newDynamicFields);
        return;
      }

      // skipping the chained fields on edit recipient
      // until the user starts to edit the fields
      if (isEdit && isSkipChain) {
        setIsSkipChain(false);
        return;
      }

      doFetchOnFilled(mappedFields);

      // remove previous children
      const chainHistory = getParents(dynamicFields);
      const affectedFields = Object.entries(chainHistory)
        // find the child fields
        .filter(([_, value]) => value.includes(dynamicFieldsKey.replace("dynamicFields.", "")))
        .map(([key]) => key);

      // unregister unused fields
      affectedFields.forEach(
        (key) => mappedFields.findIndex((item) => item.alias === key) === -1 && unregister(`dynamicFields.${key}`)
      );

      const prevFields = dynamicFields.filter((item) => !affectedFields.includes(item.alias));

      const newDynamicFields = orderFields(removeDuplicateFields([...prevFields, ...mappedFields]));

      // reset existing fields to the new value
      newDynamicFields.forEach(retainDynamicData);

      setDynamicFieldsState(newDynamicFields);
    }
  }, [dynamicFieldsData?.data?.payload]);

  // fill static fields from the recipient detail API
  useEffect(() => {
    const payload = recipientDetail?.data?.payload;
    if (payload) {
      const { legalName } = payload;
      resetField("legalName", {
        defaultValue: legalName,
      });

      payload.dynamicFields.forEach((item) => {
        // list of checkbox aliases
        const checkbox = RECIPIENT_CHECKBOX_FIELDS.includes(item.alias) && "checkbox";
        const tagInput = RECIPIENT_TAGINPUT_FIELDS.includes(item.alias) && "taginput";
        const fieldType = checkbox || tagInput || "";
        const value = defineDefaultValue(fieldType, item.value);
        if (RECIPIENT_STATIC_FIELDS.includes(item.alias)) {
          resetField(item.alias, {
            defaultValue: value,
          });
        }
      });
    }
  }, [recipientDetail?.data?.payload]);

  // set fields according to the OCR - static fields
  useEffect(() => {
    const { recipientData } = ocrData || {};
    if (recipientData) {
      Object.entries(recipientData).forEach(([key, value]: [string, string]) => {
        const transformedValue = transformOCRValue(key, value);
        if (!key.includes("dynamicFields.")) {
          resetField(key, {
            defaultValue: transformedValue,
          });
        }
      });
    }
  }, [ocrData]);

  const result = useMemo(
    () => ({
      data: {
        payload: [...staticFields, ...dynamicFields],
      },
      isValidating,
    }),
    [dynamicFields, isValidating, staticFields]
  );

  return result;
};
